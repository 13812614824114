import grey from "@material-ui/core/colors/grey"
import teal from "@material-ui/core/colors/teal"
import CssBaseline from "@material-ui/core/CssBaseline"
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Header from "../components/header2"
import "../components/theme/snipcart.css"
import "../styles/reset.css"
// import CheckIcon from '@material-ui/icons/Check';
// import ToggleButton from '@material-ui/lab/ToggleButton';

// export default function StandaloneToggleButton() {
//   const [selected, setSelected] = React.useState(false);

//   return (
//     <ToggleButton
//       value="check"
//       selected={selected}
//       onChange={() => {
//         setSelected(!selected);
//       }}
//     >
//       <CheckIcon />
//     </ToggleButton>
//   );
// }
const lightTheme = createTheme({
  root: {
    overflowX: "hidden"

  },
  palette: {
    type: "light",
    primary: {
      main: grey[900]
    },
    secondary: {
      main: teal[200]
    },
    background: {
      paper: "#22262A",
      default: "#0F1113"
    }
  },
  zIndex: {
    appBar: 19

  },
  typography: {
    h1: {
      fontSize: "3rem",
      fontWeight: 700
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 600
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 500
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 300
    }
  }
})


const darkTheme = createTheme({
  root: {
    overflowX: "hidden"

  },
  palette: {
    type: "dark",
    primary: {
      main: grey[900]
    },
    secondary: {
      main: teal[200]
    },
    background: {
      paper: "#22262A",
      default: "#0F1113"
    }
  },
  zIndex: {
    appBar: 19

  },
  typography: {
    h1: {
      fontSize: "3rem",
      fontWeight: 700
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 600
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 500
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 300
    }
  }
})




const Layout = ({ children }) => {
  const [isDarkTheme, setTheme] = useState(false)
  console.log(darkTheme)

  return (

    <>






      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Helmet>
          <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no" />
          <link rel="stylesheet" type="text/css" href="../src/styles/snipCartDark.css"></link>
        </Helmet>
        <Header />
        <div className="root">

          {children}
        </div>

      </ThemeProvider>
    </>
  )
}
export default Layout
