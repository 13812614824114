import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import MenuIcon from "@material-ui/icons/Menu"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import { Link } from "gatsby"
import React, { useState } from "react"
import Logo from "../components/svg/Logo.svg"
import "../styles/snipCartCss.css"


const useStyles = makeStyles(theme => ({
  LogoSvg: {
    height: "30px",
    width: "30px",
    fill: "currentColor"
  },
  drawer: {
    maxWidth: 300,
  },
  toolbar: {
    justifyContent: "space-between"
  },
  grid: {
    padding: 40,
    maxWidth: 300
  },
  // hiddenAppBar: {
  //   background: "transparent",
  //   zIndex: "1000",
  //   position: 'fixed'
  // },
  // headerArea: {
  //   paddingTop: 55
  // }
}))

function HideOnScroll({ children }) {
  // const trigger = useScrollTrigger()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  return (
    <>
      {/* <Slide in={!trigger} appear={false} direction="down"> */}
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <IconButton onClick={handleDrawerOpen} tabIndex="0">
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <Button>
              <Logo className={classes.LogoSvg}></Logo>
            </Button>
          </Link>
          <IconButton className="snipcart-checkout">
            <ShoppingCartIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* </Slide> */}
      <div className={classes.headerArea} />

      <div
        onKeyDown={handleDrawerClose}
        onClick={handleDrawerClose}
        tabIndex="0"
      >
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          className={classes.drawer}
        >
          <Grid
            className={classes.grid}
            spacing={3}
            alignItems="center"
            alignContent="space-around"
          >
            <Link to="/">
              <Button
                color="secondary"
                variant="text"
                size="large"
                fullWidth={true}
              >Home
              </Button>
            </Link>
            <Link to="/shop">
              <Button
                color="secondary"
                variant="text"
                size="large"
                fullWidth={true}
              >Shop
              </Button>
            </Link>
            <Divider />
            <Link to="/about-us">
              <Button
                color="secondary"
                variant="text"
                size="large"
                fullWidth={true}
              >About
              </Button>
            </Link>
            <Link to="/contact-us">
              <Button
                color="secondary"
                variant="text"
                size="large"
                fullWidth={true}
              >Contact us
              </Button>
            </Link>
            <Link to="/terms-of-service">
              <Button
                color="secondary"
                variant="text"
                size="large"
                fullWidth={true}
              >Terms of Service
              </Button>
            </Link>
            <Link to="/shipping-policy">
              <Button
                color="secondary"
                variant="text"
                size="large"
                fullWidth={true}
              >Shipping Policy
              </Button>
            </Link>
            <Link to="/frequently-asked-questions">
              <Button
                color="secondary"
                variant="text"
                size="large"
                fullWidth={true}
              >FAQ's
              </Button>
            </Link>
          </Grid>
        </Drawer>
      </div>
    </>
  )
}
const Header = () => {
  return (
    <>
      <HideOnScroll />
    </>
  )
}
export default Header
